@import "bootstrap/dist/css/bootstrap.min.css";

body {
    overflow-x: hidden;
    background: #fff;
    color: #000;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 300;
    font-size: 18px;
}

.fullscreen-block h2 {
    font-weight: 500;
    font-size: 45px;
    text-transform: uppercase;
}

.fullscreen-block .glass-btn {
    font-size: 32px;
}

.review-modal {
    max-width: 90vw;    
}

.review-modal .close {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 75px;
    height: 75px;
    z-index: 2;
    font-size: 80px;
    filter: invert(1);
    text-shadow: 0 0px 0 #fff;
}

.review-modal .modal-body {
    height: 0;
    padding-bottom: 57%!important;
    position: relative;
}

.review-modal .modal-body iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.fullscreen-block h3 {
    color: #ccc;
    font-weight: 300;
    letter-spacing: -1px;
}

.fullscreen-block p.title-desc {
    color: #9d9d9d;
    font-weight: 300;
    font-size: 22px;
}

.video-thumbnail {
    height: 100%;
}

.video-thumbnail img {
    height: 100%;
    object-fit: cover;
}

.fullscreen-block .side-bg {
    background: url(images/bronze-statue-justice-holding-up-scales.jpg);
    background-size: cover;
    background-position: right top;
}
 
.fullscreen-block {
    min-height: 100vh;
}

.bs-popover-auto[x-placement^=bottom]>.arrow, .bs-popover-bottom>.arrow {
    left: 20px;
}

.side-content h6 {
    font-size: 24px;
}

.side-content .popover {
    font-size: 12px;
}

.side-content img {
    border-radius: 50%;
}

.btn-primary {
    background: #75380b;
    border-color: #75380b;
}

.btn-primary.disabled, .btn-primary:disabled {
    background: #75380b;
    border-color: #75380b;
}

.btn-primary.focus, .btn-primary:focus {
    border-color: #75380b!important;
    box-shadow: none!important;
    background: #75380b;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background: #75380b;
    border-color: #75380b;
}

.btn-primary:hover {
    border-color: #75380b;
    background: #75380b;
}

.back-btn {
    width: 49px;
    height: 49px;
    padding: 0;
    border-color: #ccc!important;
    border-radius: 50%;
    background: transparent!important;
}

.back-btn:hover, .back-btn.focus, .back-btn:focus {
    background: transparent;
    border-color: #ccc!important;
    box-shadow: none;
}

.back-btn:hover svg {
    fill: #999;
}

.back-btn svg {
    width: 20px;
    height: 20px;
    fill: #ccc;
}

form .glass-btn {
    border-radius: 35px;
    font-size: 15px;
    display: block;
    width: 100%;
}

.form-control {
    border-radius: 25px;
}

.next-btn:hover {
    background: #75380b;
}

.next-btn {
    border-radius: 30px!important;
    box-shadow: none!important;
}

.next-btn svg {
    width: 20px;
    height: 20px;
    fill: #fff;
    margin-left: 5px;
    transform: rotate(180deg);
}

.fullscreen-block .popover {
    font-size: 13px;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    position: relative;
    max-width: unset;
    z-index: 0;
    background: #a63db8;
    color: #fff;
    animation: pulsebg 3s infinite;
}

.step-select-field .form-check-container .form-check label {
    width: 100%;
    padding: 15px 0 15px 35px;  
    line-height: 1;
    font-size: 20px;
    font-weight: 300;    
}

.step-select-field .form-check-container .form-check {
    padding-left: 0;
}

.form-check-input {
    margin-left: 10px;
    top: calc(50% - 12px);
}

.step-select-field .form-check-container {
    border: 1px solid #dadada;
    margin-bottom: 10px;
    border-radius: 5px;
}

.step-select-field .form-check-container:hover {
    background: #f6f6f6;
}

.steps-info {
    border-top: 2px solid #dadada;
}

.quiz-inner .main-content h4 {
    font-size: 38px;
    line-height: 1;
}

.quiz-inner .main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.quiz-inner {
    background: #fff;
    box-shadow: 10px 5px 30px 5px #75380b;
}

.quiz-inner .side-content {
    min-height: 510px;
}

.quiz-container {
    width: 100%;
    background: #f5f5f5;
    height: 100vh;
    flex-direction: column;
}

.form-group {
    position: relative;
}

.imgCheckPhone {
    width: 20px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.PhoneInput {
    border-radius: 50px;
    display: flex!important;
}

.PhoneInputInput {
    outline: none;
    border: none;
}

a.glass-btn svg {
    width: 36px;
    height: 36px;
    fill: #fff;
    margin-right: 20px;
}

.glass-btn {
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
	position: relative;
	padding: 10px 20px;
	text-decoration: none;
	color: #fff;
    font-size: 18px;
    font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
	overflow: hidden;
	background: #75380b;
}
.glass-btn:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: rgba(255,255,255,.1);
}
.glass-btn:after{
	content: '';
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg,transparent,rgba(255,255,255,.4),transparent);
	transition: 0.5s;
	transition-delay: 0.5s;
}

.glass-btn:hover{
    color: #fff;
    text-decoration: none;
}

.glass-btn:hover:after{
	left: 100%;
}
.glass-btn span{
	position: absolute;
	display: block;
	transition: 0.5s ease;
}
.glass-btn span:nth-child(1)
{
	top: 0;
	left: 0;
	width: 0;
	height: 1px;
	background: #fff;
}
.glass-btn:hover span:nth-child(1)
{
	width: 100%;
	transform: translateX(100%);
}
.glass-btn span:nth-child(3)
{
	bottom: 0;
	right: 0;
	width: 0;
	height: 1px;
	background: #fff;
}
.glass-btn:hover span:nth-child(3)
{
	width: 100%;
	transform: translateX(-100%);
}
.glass-btn span:nth-child(2)
{
	top: 0;
	left: 0;
	width: 1px;
	height: 0;
	background: #fff;
}
.glass-btn:hover span:nth-child(2)
{
	height: 100%;
	transform: translateY(100%);
}
.glass-btn span:nth-child(4)
{
	bottom: 0;
	right: 0;
	width: 1px;
	height: 0;
	background: #fff;
}

.glass-btn:hover span:nth-child(4)
{
	height: 100%;
	transform: translateY(-100%);
}

.side-footer {
    padding: 15px;
    border-top: 1px solid #ccc;
    width: 100%;
}

.side-content .alert-warning {
    font-size: 20px;
}

.alert-warning {
    font-weight: 600;
}

.alert-warning .arrows {
    position: absolute;
    right: 10px;
    top: 0px;
}

.alert-warning .arrows svg {
    width: 10px;
    height: 10px;
    animation-name: move-arrow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.steps-info .alert-warning {
    font-size: 11px;
    padding: 4px 30px 0px 8px;
    margin: 0;
    margin-right: 10px;
    display: none;
}

.your-discount {
    display: flex;
    font-size: 12px;
    font-weight: 700;
}

.your-discount span.label {
    background: #f4b7ff;
    padding: 5px;
    border-radius: 5px;
}

.your-discount span.value svg {
    width: 12px;
    height: 12px;
    fill: #52bc77;
    z-index: 100;
    position: relative;
}

.your-discount span.value {
    background: #f4b7ff;
    padding: 5px;
    border-radius: 5px;
    min-width: 30px;
    position: relative;
    margin-left: -1px;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.your-discount span.value:before { 
    content: "";
    position: absolute;
    right: -8px;
    width: 20px;
    height: 20px;
    background: #f4b7ff;
    top: 4px;
    transform: rotate(45deg);
    border-radius: 5px;
}

.your-discount span.value:after { 
    content: "";
    position: absolute;
    right: -1px;
    width: 6px;
    height: 6px;
    background: #fff;
    top: calc(50% - 3px);
    border-radius: 50%;
    border: 1px solid #afafaf;
}











.box-progress-placeholder {
  display: block;
  height: 10px;
  border-radius: 6px;
  background: #ccc;
  width: 100%;
  position: absolute;
  top: 0;
}
.box-progress {
  display: block;
  height: 10px;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 5px 0px #75380b;
}
.box-progress-bar {
  width: 100%;
  height: 10px;
  position: relative;
  top: 0;
  border-radius: 6px;
  background-color: #fff;
  margin: 8px 0;
}
.box-progress-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin: 0;
}

.box-progress-header span {
    font-weight: 900;
    color: #75380b;
}

.box-progress-percentage {
  text-align: right;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.agree-label u {
    margin-left: 5px;
    margin-right: 5px;
}

.agree-label input {
    margin-right: 5px;
}

.agree-label {
    font-size: 18px;
    align-items: center;
}

.step-select-hint {
    margin-top: 20px;
    color: #9f9f9f;
    font-size: 19px;
    line-height: 1;
}

.step-select-hint .col {
    display: flex;
    align-items: center;
}

.step-select-hint img {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.step-select-hint svg path {
    fill: #ccc;
}

@media (max-height: 640px) {
    .container {
        max-width: 100%!important;
    }    

    .side-footer {
        position: relative;
        margin-top: 50px;
    }

    .fullscreen-block h2 {
        font-size: 24px;
    }
    
    .fullscreen-block p.title-desc {
        font-size: 14px;
    }
    
    .quiz-container {
        height: auto;
        padding: 0!important;
    }
    
    .step-content {
        padding-bottom: 10px!important;
    }
    
    .quiz-inner .side-content {
        min-height: unset;
    }
}

@media (max-width: 767px) {
    .video-thumbnail img {
        max-height: 33vh;
        width: 100%;
        object-position: center;
    }

    .side-bg {
        height: 250px;
        background-size: cover!important;
        background-position: top center!important;
    }
    
    .fullscreen-block {
        flex-direction: column;
    }    
    
    .fullscreen-block .popover {
        top: 20px;
    }
    
    .step-select-field .form-check-container .form-check label {
        font-size: 16px;
    }    
    
    .side-content {
        display: none;
    }
    
    .main-content {
        min-height: 100vh;
    }
    

    .steps-info .alert-warning {
        display: block;
    }  

    .side-footer {
        position: relative;
        margin-top: 200px;
    }     
    
    .your-discount {
        position: relative;
        top: -38px;
    }
    
    .steps-info {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px; 
        background: #fff;
        width: 100%;
        margin: 0;
    }
    
    .fullscreen-block > div:not(.side-bg) {
        order: 1;
    }    
    
    .fullscreen-block h2 {
        font-size: 30px;
    }
    
    .fullscreen-block h3 {
        font-size: 24px;
    }
    
    .fullscreen-block p.title-desc {
        font-size: 18px;
    }
    
    .fullscreen-block .glass-btn {
        font-size: 20px;
    }
    
    .quiz-inner .main-content h4 {
        font-size: 28px;
    }
    
    .step-content {
        margin-bottom: 200px;
    }
}

@media screen and (min-width: 769px) {
    .fullscreen-block {
        overflow: hidden;
    }
}

@media screen and (max-width: 768px) {
    .quiz-inner .side-content {
        min-height: unset;
    }
    
    h1 {
        font-size: 26px;
    }
    
    .quiz-container {
        height: auto;
        position: relative;
    }      
    
    .fullscreen-block .side-bg {
        background: url(images/bronze-statue-justice-holding-up-scales-mobile.jpg);
    }    
}

@media screen and (max-width: 450px) {
    h2 {
        font-size: 24px;
    }
}

@keyframes spin { 
  100% { 
    transform: rotateZ(360deg);
  }
}

@keyframes pulsebg { 
  50% {
    background: #75380b;
  }
  100% {
    background: #d717b5;
  }
}

@keyframes pulseborder { 
  50% {
    border-bottom-color: #75380b;
  }
  100% {
    border-bottom-color: #d717b5;
  }
}

@keyframes spinback { 
  100% { 
    transform: rotateZ(-360deg);
  }
}

@keyframes move-arrow{0%{-webkit-transform:translate(0);transform:translate(0)}50%{-webkit-transform:translateY(60%);transform:translateY(60%)}to{-webkit-transform:translate(0);transform:translate(0)}}